<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table>
        <h1>{{ name }}</h1>
      </template>
      <template v-slot:item-slot>
        <div class="breadcrumb-item">Item</div>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <h2 class="section-title">Forms</h2>
      <p class="section-lead">
        Examples and usage guidelines for form control styles, layout options,
        and custom components for creating a wide variety of forms.
      </p>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }}</h4>
            </div>
            <form @submit.prevent="submit">
              <div class="card-body">
                <div class="form-group">
                  <label>Item</label>
                  <select class="custom-select" v-model="form.item_id">
                    <option selected="" value="">Open this select menu</option>
                    <option
                      v-for="item in data.items"
                      :key="item.value"
                      :value="item.value"
                      >{{ item.text }}</option
                    >
                  </select>
                  <small class="text-danger" v-if="errors.item_id">{{
                    errors.item_id[0]
                  }}</small>
                </div>
                <div class="form-group">
                  <label>Product</label>
                  <select class="custom-select" v-model="form.product_id">
                    <option selected="" value="">Open this select menu</option>
                    <option
                      v-for="product in data.products"
                      :key="product.value"
                      :value="product.value"
                      >{{ product.text }}</option
                    >
                  </select>
                  <small class="text-danger" v-if="errors.product_id">{{
                    errors.product_id[0]
                  }}</small>
                </div>
                <div class="form-group">
                  <label>Unit</label>
                  <select class="custom-select" v-model="form.unit_id">
                    <option selected="" value="">Open this select menu</option>
                    <option
                      v-for="unit in data.units"
                      :key="unit.value"
                      :value="unit.value"
                      >{{ unit.text }}</option
                    >
                  </select>
                  <small class="text-danger" v-if="errors.unit_id">{{
                    errors.unit_id[0]
                  }}</small>
                </div>
                <div class="form-group">
                  <label>Quantity</label>
                  <input
                    type="number"
                    class="form-control"
                    v-model="form.qty"
                  />
                  <small class="text-danger" v-if="errors.qty">{{
                    errors.qty[0]
                  }}</small>
                </div>
              </div>
              <div class="card-footer text-right">
                <button class="btn btn-primary mr-1" type="submit">
                  Submit
                </button>
                <button class="btn btn-secondary" type="reset">Reset</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";

import {
  ItemRepository,
  ProductRepository,
  UnitRepository,
} from "../../repositories/RepositoryFactory";
export default {
  name: "SubitemEdit",
  data() {
    return {
      name: "Sub Item Edit",
      form: {
        item_id: "",
        product_id: "",
        unit_id: "",
        qty: "",
      },
      data: {
        items: [],
        products: [],
        units: [],
      },
      errors: [],
      isSubmitting: false,
    };
  },
  mounted() {
    this.getItems();
    this.getProducts();
    this.getUnits();
    this.getData();
  },
  methods: {
    getItems() {
      ItemRepository.getForSelect().then(({ data }) => {
        this.data.items = data.data;
      });
    },
    getProducts() {
      ProductRepository.getMinimal(true).then(({ data }) => {
        this.data.products = data.data;
      });
    },
    getUnits() {
      UnitRepository.getForSelect().then(({ data }) => {
        this.data.units = data.data;
      });
    },
    submit() {
      this.isSubmitting = true;

      let id = this.$route.params.id;
      axios
        .post(this.$store.state.api + "sub_item/update/" + id, this.form, {
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then(() => {
          this.$noty.success("Your unit has been updated!");
          this.$router.back();
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.isSubmitting = false;
        });
    },
    getData() {
      let id = this.$route.params.id;
      axios
        .get(this.$store.state.api + "sub_item/" + id, {
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then((res) => {
          this.form.item_id = res.data.data.item_id;
          this.form.product_id = res.data.data.product_id;
          this.form.unit_id = res.data.data.unit_id;
          this.form.qty = res.data.data.qty;
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>
